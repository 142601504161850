import { formatDate, humanizeCount, formatPrice } from '../helpers';

import { BaseModel } from './base.model';
import { OrderFulfillmentModel, OrderActivityModel, OrderMessageModel, OrderItemModel } from './index';

export class OrderModel extends BaseModel<any> {
  private claimMessages: any[] = [];

  loadMessages(messages: any[], opts?: { replace?: boolean }) {
    if (messages?.length > 0) {
      this.claimMessages = opts?.replace ? messages : this.claimMessages.concat(messages);
    }
  }

  get id() {
    return this._data.id;
  }

  get eligibleForClaim() {
    return this._data.policyId || this._data.eligibleForOta === 'ELIGIBLE';
  }

  get eligibleForOta() {
    return (
      (this._data.policyId && this._data.eligibleForOta === 'IS_OTA') ||
      (!this._data.policyId && this._data.eligibleForOta === 'ELIGIBLE')
    );
  }

  get printableId() {
    return this._data.sourceOrderNumber;
  }

  get customer() {
    return this._data.customerName;
  }

  get date() {
    return formatDate(this._data.createdAt);
  }

  get sourceOrderId() {
    return this._data.sourceOrderId;
  }

  get originalSourceOrderId() {
    return this._data.originalSourceOrderId;
  }

  get data() {
    return this._data;
  }

  get platformId() {
    return this._data.platformId;
  }

  get shippingAddressChange() {
    const shippingAddresses = this._data.address.filter(address => address.type === 'SHIPPING');

    return shippingAddresses.length > 1 ? 'Yes' : 'No';
  }

  get url() {
    return `/claims/view/${this.id}`;
  }

  get itemsCount() {
    return this._data?.orderItems?.length;
  }

  get itemsCountHumanized() {
    return humanizeCount(this.itemsCount, 'item', 'items');
  }

  get premiumPaid() {
    return this._data.premiumPaid;
  }

  get address() {
    return this._data.address;
  }

  get customerName() {
    return this._data.customerName;
  }

  get customerPhone() {
    return this._data.customerPhone;
  }

  get customerEmail() {
    return this._data.customerEmail;
  }

  get customerObj() {
    return this._data.customer;
  }

  get orderItems() {
    return this._data.orderItems;
  }

  get shippingCost() {
    return this._data.shippingCost;
  }

  get shippingCostTax() {
    return this._data.shippingCostTax;
  }

  get shippingCostInDollars() {
    const shippingCostInDollars = this._data.shippingCost * this._data.exchangeRate;

    return +shippingCostInDollars.toFixed(2);
  }

  get shippingTaxInDollars() {
    const shippingTaxInDollars = this.shippingCostTax * this._data.exchangeRate;

    return +shippingTaxInDollars.toFixed(2);
  }

  get tax() {
    return this._data.tax;
  }

  get currency() {
    return this._data.currency;
  }

  get total() {
    return this._data.total;
  }

  get exchangeRate() {
    return this._data.exchangeRate;
  }

  get payment() {
    let conversion = null;
    if (this._data.exchangeRate !== 1) {
      conversion = {
        currency: this._data.currency,
        price: this._data.total.toFixed(2),
      };
    }
    const premiumPaid = this._data?.policy?.premiumPaid ?? 0;
    const protectionPrice = premiumPaid * this._data.exchangeRate;
    const shippingCost = this._data.shippingCost * this._data.exchangeRate;
    const shippingCostTax = this._data.shippingCostTax * this._data.exchangeRate;
    const tax = this._data.tax * this._data.exchangeRate;

    return {
      conversion,
      currency: this._data.currency,
      lineItems: this.products,
      protection: {
        price: protectionPrice.toFixed(2),
      },
      shipping: {
        currency: this._data.currency,
        price: shippingCost.toFixed(2),
        tax: shippingCostTax.toFixed(2),
        title: '',
      },
      tax: {
        // percent: '15%',
        currency: this._data.currency,
        price: tax.toFixed(2),
      },
      total: {
        price: formatPrice(this._data.total * this._data.exchangeRate, 'USD'),
      },
    };
  }

  get fulfillments() {
    return OrderFulfillmentModel.fromArrayExtending(this._data.fulfillments, { order: this });
  }

  get storeId() {
    return this._data.storeId;
  }

  get unfulfilledItems() {
    const unfulfilledItems = this.products
      .filter((product: any) => product.quantity !== product.fulfilledQuantity)
      .map((product: any) => ({
        ...product._data,
        quantity: product.quantity - product.fulfilledQuantity,
      }));

    return OrderItemModel.fromArrayExtending(unfulfilledItems, {
      currency: this.currency,
      order: this,
    });
  }

  get reshipments() {
    return this._data.reshipments;
  }

  get products() {
    return OrderItemModel.fromArrayExtending(this._data.orderItems, {
      currency: this.currency,
      order: this,
    });
  }

  get messages() {
    return OrderMessageModel.fromArray(this.claimMessages || this.messagesTemplate);
  }

  get activities() {
    const activities = this._data.activities;
    const orderActivities =
      this?._data?.orderActivities?.edges.map(activity => {
        const { id, source, sourceType, activityType, description, createdAt, imageUrl } = activity.node;

        return {
          activityType,
          createdAt,
          cursor: '',
          id,
          imageUrl,
          message: description,
          source,
          sourceType,
        };
      }) ?? [];

    return OrderActivityModel.fromArray(activities.concat(orderActivities));
  }

  get allMessagesAndActivities() {
    return [...this.messages, ...this.activities].sort((a: any, b: any) => {
      return a.createdAtTime - b.createdAtTime;
    });
  }

  private get messagesTemplate() {
    return [
      {
        createdAt: '2021-08-01T12:00:00Z',
        image: '/headshots/john.jpeg',
        medias: [{ caption: '', fileKey: '/headshots/john.jpeg' }],
        message: `
    Hi Allison,

    Thank you for contacting us. We're happy to help get resolved wtih this. I can see that you ordered Immune revival (Stick Packs: Unflavored), may I know how many packets are damaged?

    Thanks!
    `,
        messageUserType: 'system',
        type: 'message',
        username: 'Raul Clavecillas',
      },
      {
        createdAt: '2021-08-01T12:30:00Z',
        message: `It's hard to tell because the packets are so thin. I've tried to open a few of them but there is hardly anything that comes out of one and then the next one is fine.`,
        messageUserType: 'user',
        type: 'message',
        username: 'Stacey Renza',
      },
      {
        createdAt: '2021-08-01T13:00:00Z',
        image: '/headshots/john.jpeg',
        medias: [{ caption: '', fileKey: '/headshots/john.jpeg' }],
        message: `
    Thank you for your responses to us. Can you tell if the product is damaged during the shipment? Is the box of the parcel damaged as well?

    Thanks!
    `,
        messageUserType: 'system',
        type: 'message',
        username: 'Raul Clavecillas',
      },
      {
        createdAt: '2021-08-01T13:30:00Z',
        message: `Yes the parcel was smushed therefore causing the product to be damaged. I sent in pictures of the box before I opened it as well as the box after opening it`,
        messageUserType: 'user',
        type: 'message',
        username: 'Stacey Renza',
      },
      {
        createdAt: '2021-08-01T14:00:00Z',
        image: '/headshots/john.jpeg',
        medias: [{ caption: '', fileKey: '/headshots/john.jpeg' }],
        message: `
Hi Allison,

We're sorry about the damaged item. I have shipped your replacement order.

It will be sent using the same shipping method you originally purchased.

You will receive an order confirmation shortly and a shipping confirmation as soon as the order goes out. Have a great day!
`,
        messageUserType: 'system',
        type: 'message',
        username: 'Raul Clavecillas',
      },
    ];
  }
}
