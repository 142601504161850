import { formatDate, removeHTMLTags } from '../helpers';

import { BaseModel } from './base.model';

export class OrderActivityModel extends BaseModel<any> {
  get key() {
    return this._data.id || this._data.createdAt;
  }

  get image() {
    return this._data.imageUrl;
  }

  get message() {
    return removeHTMLTags(this._data.message);
  }

  get rawMessage() {
    return this._data.message;
  }

  get date() {
    return formatDate(this._data.createdAt);
  }

  get createdAtTime() {
    return new Date(this._data.createdAt).getTime();
  }

  get messageUserType() {
    return this._data.source?.toLowerCase();
  }

  get type() {
    return this._data.activityType.toLowerCase();
  }

  get username() {
    return this._data.username;
  }
}
